import { Link } from 'gatsby';
import React from 'react';

import * as style from './breadcrumbs.module.scss';

const Breadcrumbs = ({ breadcrumbs }) => {
  return (
    <div className={style.main}>
      <Link to={'/'} className={style.link}>
        Home
      </Link>
      {breadcrumbs.map((link, index) =>
        link.url ? (
          <Link key={link.url} to={link.url} className={style.link}>
            {link.name}
          </Link>
        ) : (
          <span key={index} className={style.link}>
            {link.name}
          </span>
        ),
      )}
    </div>
  );
};

export default Breadcrumbs;
