import React from 'react';

import Breadcrumbs from '@/components/common/Breadcrumbs';
import Seo from '@/components/seo';
import { ROUTE_NAMES } from '@/utils/url';

import * as style from './terminoscondiciones.module.scss';

const TermsConditionsPage = () => {
  const breadcrumbs = [{ url: `/${ROUTE_NAMES.TERMS_CONDITIONS}`, name: 'Términos y Condiciones' }];
  return (
    <>
      <Seo
        title="Términos y condiciones"
        description="Conocé todos los aspectos legales y contractuales de Visciglio Audio &amp; Instrumentos"
      />
      <section className={style.main}>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <div>
          <section>
            <h1>Términos y Condiciones</h1>
            <p>
              Los términos y condiciones generales referidos a continuación, rigen exclusivamente
              las relaciones contractuales entre todo usuario (denominado en adelante “Usuario”) que
              accede al Sitio Web{' '}
              <a href="https://www.visciglio.com" target="_blank" rel="noopener noreferrer">
                www.visciglio.com
              </a>{' '}
              (en adelante "Website”) y el propietario del Sitio Web, que es la sociedad Visciglio
              S.A.S., con domicilio en Gallardo 640, San Carlos de Bariloche, Río Negro, República
              Argentina (en adelante "la Empresa"). El Usuario que accede a Website está obligado a
              conocer, aceptar y se compromete a respetar los Términos y Condiciones Generales. En
              consecuencia, todas las visitas y cada uno de los contratos y transacciones que se
              realicen en este sitio, como asimismo sus efectos jurídicos, entre los Usuarios y
              Visciglio S.A.S., quedarán regidos por estas reglas y sometidas a la legislación
              aplicable en la República Argentina.
            </p>
            <p className={style.info}>
              IMPORTANTE: Antes de cada contratación, el Usuario deberá leer, entender y aceptar
              todas las condiciones establecidas en los Términos y Condiciones Generales y en las
              Políticas de Privacidad de Visciglio S.A.S.
            </p>
          </section>
          <section>
            <h2>1. Aceptación Términos y Condiciones</h2>
            <p>
              Los presentes Términos y Condiciones tienen carácter obligatorio y vinculante. Se
              aplican exclusivamente a todas las compras y actividades realizadas mediante el
              Website, no a las adquisiciones realizadas de modo presencial en alguno de los locales
              de Visciglio S.A.S. El uso del Sitio implica el conocimiento y la aceptación de ellos.
              Si usted no está de acuerdo con los Términos y Condiciones, deberá abstenerse de
              utilizar el Website y/o los servicios por él ofrecidos. Por “Usuario” del Website se
              entiende a todas aquellas personas que utilicen el Website, estén o no registrados en
              el mismo.
            </p>
          </section>
          <section>
            <h2>2. Modificación de los Términos y Condiciones</h2>
            <p>
              Visciglio S.A.S. podrá, a su exclusivo criterio, sustituir o modificar los Términos y
              Condiciones en cualquier momento, sin necesidad de requerir el consentimiento de los
              Usuarios. Para las transacciones en curso que hayan comenzado con anterioridad a
              dichas modificaciones, subsistirán las condiciones vigentes al momento de su
              concertación, a menos que las nuevas modificaciones introducidas fueran más
              convenientes para el Usuario. Los Términos y Condiciones, así como sus modificaciones
              son vigentes en forma inmediata a su publicación en el Website. En caso de no aceptar
              algún cambio o modificación en las Condiciones de Uso, deberá cesar inmediatamente en
              la utilización del Sitio y/o los Servicios.
            </p>
          </section>
          <section>
            <h2>3. Capacidad</h2>
            <p>
              Para utilizar los servicios del Website se requiere tener capacidad legal para
              contratar conforme lo establecido por la normativa vigente. No podrán acceder a los
              servicios quienes carezcan de ella, los que hayan sido suspendidos o inhabilitados, ni
              los menores de edad. Los padres, tutores o responsables de los menores de edad o
              incapaces que utilicen el Website serán responsables por dicho uso, incluyendo
              cualquier cargo, facturación o daño que se derive de él. En caso de actuar en
              representación de una persona jurídica, el usuario debe contar con capacidad para
              contratar a nombre de tal entidad y de obligar a la misma en los términos de este
              documento. Si el Usuario no contare con tales facultades, o actuare en exceso de las
              mismas, se entenderá que se ha comprometido también a título personal.
            </p>
          </section>
          <section>
            <h2>4. Interrupción del servicio</h2>
            <p>
              Visciglio S.A.S. se reserva el derecho de interrumpir, suspender o modificar en
              cualquier momento los servicios ofrecidos en el presente Website, ya sea en forma
              permanente o transitoria. No se requerirá la conformidad de los Usuarios, ni será
              necesario aviso previo alguno. Asimismo, Visciglio S.A.S. no garantiza la
              funcionalidad ni el acceso o uso permanente del Website, que podría interrumpirse por
              cuestiones técnicas ajenas a Visciglio S.H. Visciglio S.A.S. no garantiza que el
              Website se encuentre libre de virus o cualquier otro elemento que pueda llegar a dañar
              o alterar el normal funcionamiento de un ordenador. Es responsabilidad y obligación
              exclusiva del Usuario contar con las herramientas adecuadas para detectar, desinfectar
              y/o prevenir cualquier tipo de elementos y/o posibles daños de esta naturaleza.
              Visciglio S.A.S. no se responsabiliza por cualquier daño que pueda producirse en los
              equipos informáticos de los Usuarios o de terceros como consecuencia de la navegación
              del presente Website.
            </p>
          </section>
          <section>
            <h2>5. Política de privacidad</h2>
            <p>
              La información personal que los Usuarios ingresan en el Website es totalmente
              confidencial y Visciglio S.A.S. hará su mejor esfuerzo para proteger la privacidad de
              los mismos, de conformidad con lo dispuesto en la Ley 25.326. Cualquier Usuario del
              Website tendrá derecho a solicitar y obtener información sobre los datos personales
              que Visciglio S.A.S. tenga en su poder, quedando la Empresa obligada a proporcionar la
              información solicitada dentro de los diez días corridos de haber sido intimada
              fehacientemente. Los Usuarios también podrán ejercer el derecho de rectificación,
              cuando los datos que se posean fueran incorrectos. Visciglio S.A.S. garantiza a sus
              Usuarios que utilizará los datos dentro de las pautas establecidas por la Ley 25.326
              de Protección de los Datos Personales. En caso de que los datos sean requeridos por la
              vía legal, administrativa o judicial correspondiente, Visciglio S.A.S. se verá
              compelida a revelar los mismos a la autoridad solicitante. En la medida en que la
              legislación y normas de procedimiento lo permitan, Visciglio S.A.S. informará a los
              Usuarios sobre estos requerimientos. Por el sólo hecho de realizar una consulta en el
              formulario de contacto del Website, los Usuarios aceptan que Visciglio S.A.S. tiene
              derecho a comunicarse con ellos por vía postal, telefónica o electrónica y enviar
              información que la empresa considere, a su exclusivo criterio, que pueda ser de su
              interés, incluyendo publicidad e información sobre ofertas y promociones. En caso de
              que los Usuarios no deseen ser contactados con estos fines, podrán manifestárselo
              fehacientemente a Visciglio S.H. quien procederá a interrumpir este tipo de
              comunicaciones en el menor tiempo que le sea posible.
            </p>
          </section>
          <section>
            <h2>6. Cookies</h2>
            <p>
              El Website puede utilizar un sistema de seguimiento mediante “cookies”, para que el
              acceso a la información, al pasar de página en página, se realice con mayor rapidez.
              Estas cookies son pequeños archivos que envía la página visitada y se alojan en el
              disco duro del ordenador, ocupando poco espacio. Se hace saber a los Usuarios que
              utilizando las opciones de su navegador podrán limitar o restringir según su voluntad
              el alojamiento de estas “cookies”, aunque es desaconsejable restringirlas totalmente.
              El sistema podrá recoger información sobre sus preferencias e intereses. En el caso de
              que esto ocurra, la información será utilizada exclusivamente con fines estadísticos
              para mejorar los servicios que se prestan en el Website. Visciglio S.A.S. aplicará, en
              la mayor medida en que sea posible, procedimientos de disociación de la información de
              modo que los titulares de los datos sean inidentificables.
            </p>
          </section>
          <section>
            <h2>7. Disponibilidad y precio de los productos</h2>
            <p>
              Antes de realizar una compra, el Usuario deberá tener en cuenta que los productos
              publicados en el Website pueden no encontrarse en stock, o poseer un precio
              desactualizado. Toda compra se encuentra sujeta a disponibilidad.
            </p>
          </section>
          <section>
            <h2>8. Imágenes</h2>
            <p>
              Las imágenes utilizadas para ilustrar la presentación de los productos no responden
              necesariamente a la presentación normal de cada ítem en todas sus variantes, tamaños o
              formulaciones. Su uso es ilustrativo pero, no contractual.
            </p>
          </section>
          <section>
            <h2>9. Validez de las promociones</h2>
            <p>
              En el caso de que se realicen ofertas y promociones de productos, éstas tendrán
              validez para las compras efectuadas durante el período de vigencia de las ofertas y
              promociones. Los términos y condiciones de las mismas serán comunicados en el Website,
              y estarán siempre sujetas a la existencia en stock de los productos ofrecidos. Las
              ofertas y promociones de productos que se ofrezcan a través del Website podrán diferir
              de aquellas promociones y ofertas que se realicen en las distintas sucursales de
              Visciglio S.H.
            </p>
          </section>
          <section>
            <h2>10. Moneda</h2>
            <p>
              Todos los precios en el Website están expresados en pesos argentinos, moneda de curso
              legal de la República Argentina.
            </p>
          </section>
          <section>
            <h2>11. Impuesto al Valor Agregado (IVA)</h2>
            <p>
              Todos los precios expresados en el Website incluyen IVA, salvo que se indique lo
              contrario.
            </p>
          </section>
          <section>
            <h2>12. Envío de productos</h2>
            <p>
              Las entregas se realizarán en la dirección que el Usuario indique. La validez de la
              misma es de su exclusiva responsabilidad. No se entregarán órdenes a casillas de
              correo (P.O. Box). El tiempo de entrega depende de la disponibilidad del producto, del
              tiempo de envío y de la aprobación del medio de pago. Los días que se indiquen son
              estimativos. Los envíos se realizan dentro del territorio continental de la República
              Argentina e Isla de Tierra del Fuego, con exclusión de la Antártida e Islas del
              Atlántico Sud. No se realizan envíos al exterior del país. Para asegurar la máxima
              eficacia en las entregas, éstas se realizan mediante empresas especializadas. El
              tiempo de aprobación varía según el medio de pago. El tiempo de envío varía según el
              destino donde se solicite la entrega.
            </p>
          </section>
          <section>
            <h2>13. Gastos de envío</h2>
            <p>
              El Usuario será claramente informado de los costos de entrega antes de realizar la
              compra. Estos costos son calculados en función del peso total y/o el volumen total del
              envío, así como de la distancia existente entre el domicilio de despacho del producto
              y el domicilio de entrega. Los costos de envío serán discriminados como ítem separado
              dentro de la factura. Visciglio S.A.S. está siempre trabajando para mejorar la calidad
              y el costo de entrega para sus clientes. Por este motivo, dichos costos y las
              políticas de envío se hallan sujetas a cambio sin previo aviso. En caso de resultar
              necesario despachar la mercadería a un área aduanera especial, o como mercadería en
              tránsito por territorio de países limítrofes, la contratación del servicio de
              despachante de aduanas, así como el pago de aranceles y gastos de almacenamiento en
              depósito fiscal, correrán por exclusiva cuenta y cargo del cliente.
            </p>
          </section>
          <section>
            <h2>14. Cancelación órdenes de compra</h2>
            <p>
              El Usuario podrá cancelar una orden, siempre y cuando ésta no haya sido aún
              despachada. Para esto deberá ponerse en contacto con Visciglio S.A.S. dentro de las 24
              horas de realizada la compra enviando un e-mail a ventas@visciglio.com. Si la
              cancelación de la compra es total, se reintegrará el importe en Pesos on-line en la
              Cuenta de Usuario, o mediante el medio de pago que se utilizó para abonar.
            </p>
          </section>
          <section>
            <h2>15. Facturación</h2>
            <p>
              Al adquirir un producto el Usuario deberá pagar el precio publicado y en caso de
              corresponder los gastos de envío y entrega. La factura electrónica a emitirse será la
              de consumidor final (comprobantes tipo “B”), siendo esta única modalidad de
              facturación para la compra on line. La misma será enviada al e-mail informado por el
              Usuario en el pedido, sin excepción. Visciglio S.A.S. se reserva el derecho de
              modificar, cambiar, agregar o eliminar los precios vigentes, en cualquier momento, lo
              cual será publicado en el sitio. Asimismo, Visciglio S.A.S. podrá modificar
              temporalmente los precios por razón de promociones, siendo efectivas estas
              modificaciones cuando se haga pública la promoción, o se realice el anuncio y hasta la
              fecha de su finalización. Visciglio S.A.S. se reserva el derecho de tomar las medidas
              judiciales y extrajudiciales que estime pertinentes para obtener el pago del monto
              debido. En caso de haberse facturado cargos que no que no hubiesen correspondiendo y/o
              el Usuario no hubiese recibido la factura electrónica en la dirección de e-mail
              informado en el pedido, deberá comunicarse con nuestro equipo de Atención al Cliente
              para resolver el inconveniente suscitado.
            </p>
          </section>
          <section>
            <h2>16. Prohibiciones</h2>
            <p>
              Se les prohíbe terminantemente a los Usuarios: a) enviar comentarios cuyo contenido
              sea ilegal, obsceno, abusivo, difamatorio, injurioso o contrario a las buenas
              costumbres (la presente enumeración es meramente ejemplificativa); b) enviar archivos
              que contengan virus o cualquier otra característica capaz de dañar el funcionamiento
              de una computadora, del Website o del sistema; c) utilizar el Website para violar
              cualquier tipo de norma vigente; d) consignar datos falsos al momento de registrarse o
              realizar una compra, o cualquier otro momento en que les sea requerida cualquier tipo
              de información o datos personales; e) ofrecer productos o servicios; f) usar
              programas, software o aparatos automáticos o manuales para monitorear o copiar la
              información o cualquier tipo de contenido del Sitio sin previo consentimiento de
              Visciglio S.A.S.
            </p>
          </section>
          <section>
            <h2>17. Declaraciones</h2>
            <p>
              Visciglio S.A.S. no se hace responsable por la veracidad de la información incorporada
              al Website por terceros. Tampoco se hace responsable en cuanto haya sido reproducida o
              comunicada directamente por los Usuarios del Website sin verificación por parte de
              Visciglio S.A.S. Si algún Usuario se viera afectado por la información a la que se
              alude en el párrafo anterior, deberá comunicárselo a Visciglio S.A.S., por mail o
              correo postal, a fin de que se proceda a la supresión de la misma.
            </p>
          </section>
          <section>
            <h2>18. Derechos reservados y propiedad intelectual</h2>
            <p>
              Todos los derechos del presente Website están reservados y corresponden a Visciglio
              S.A.S. El contenido del presente Website, incluyendo aunque no limitado al texto,
              logos, gráficos, y todo el diseño en general, así como su base de datos y software, es
              de propiedad de Visciglio S.A.S. o tiene derecho a usarlo en virtud de licencias de
              uso otorgadas y se encuentra protegido por las legislación nacional e internacional
              vigente sobre propiedad intelectual. Si el Usuario considera que en el Website se
              viola o atenta de algún modo contra derechos de propiedad intelectual de terceros
              deberá notificarlo a Visciglio S.A.S. en la dirección indicada en los presentes
              Términos y Condiciones Generales, acompañando toda la información y documentación
              necesaria que respalde la mencionada consideración.
            </p>
          </section>
          <section>
            <h2>19. Razón social y domicilio</h2>
            <p>
              La razón social de la empresa es Visciglio S.A.S. El domicilio legal es Gallardo 640,
              San Carlos de Bariloche, Río Negro, Argentina.
            </p>
          </section>
          <section>
            <h2>20. Notificaciones</h2>
            <p>
              Todas las notificaciones y/o comunicaciones que deban efectuarse por el uso de Website
              bajo estos Términos y Condiciones Generales, deberán realizarse por escrito: (i) al
              Usuario: mediante correo electrónico, a la cuenta de correo consignada por éste, o por
              carta documento, al domicilio declarado en el formulario de registración; (ii) a
              Visciglio S.A.S. a la cuenta de correo electrónico ventas@visciglio.com a su domicilio
              legal indicado en el punto anterior.
            </p>
          </section>
          <section>
            <h2>21. Avisos publicitarios y links</h2>
            <p>
              Cuando el Usuario hace “click” en avisos publicitarios o links de terceros e ingresa
              en otros sitios que no pertenecen a Visciglio S.H. estará sujeto a los términos y
              condiciones de dichos sitios. El Usuario deberá leer detenidamente sus políticas de
              acceso y uso. Visciglio S.A.S. no garantiza la legalidad, actualidad, calidad ni
              utilidad de los contenidos, operaciones e informaciones que se comuniquen, reproduzcan
              y/o realicen en sitios enlazados de terceros ni la ausencia de nocividad de tales
              contenidos o servicios, por lo que el Usuario exime de toda responsabilidad a
              Visciglio S.A.S. por los contenidos incluidos en los referidos sitios o los servicios
              que en ellos se brindan o promocionan.
            </p>
          </section>
          <section>
            <h2>22. Jurisdicción y ley aplicable</h2>
            <p>
              Los presentes Términos y Condiciones se encuentran regidos sin excepción y en todos
              sus puntos por las leyes de la República Argentina y serán interpretados de acuerdo a
              ellas. Ante cualquier diferencia, desacuerdo o conflicto derivado de la
              interpretación, validez, alcance y/o aplicación de los presentes Términos y
              Condiciones Generales, los Usuarios se comunicarán con Visciglio S.H. de manera
              fehaciente, haciéndole llegar su reclamo, para que las partes traten de arribar a un
              acuerdo. En caso de que no sea posible arribar a una solución, y para garantizar a los
              consumidores el pleno acceso a la justicia, los Usuarios podrán elegir y someter su
              reclamo a una de las siguientes opciones e instancias: Sistema Nacional de Arbitraje
              de Consumo del Ministerio de Economía y Producción de la Nación. Los procedimientos
              ante este Sistema son gratuitos, y no es necesario contar con patrocinio letrado.
              Asimismo, se garantiza el equilibrio entre las partes y la transparencia del proceso,
              y los laudos emitidos por dicho Tribunal tienen autoridad de cosa juzgada y son
              irrecurribles (para saber más sobre este sistema ingresar en{' '}
              <a
                href="https://www.argentina.gob.ar/produccion/consumidor/sistema-nacional-de-arbitraje-de-consumo"
                target="_blank"
                rel="noopener noreferrer"
              >
                argentina.gob.ar/produccion/consumidor/sistema-nacional-de-arbitraje-de-consumo
              </a>
              ). Para el caso de que las opciones anteriores fuesen gravosas o económicamente
              inviables para Usuarios domiciliados en el exterior, las partes, a pedido del Usuario,
              determinarán de común acuerdo un mecanismo mutuamente conveniente para resolver sus
              diferencias.
            </p>
          </section>
        </div>
      </section>
    </>
  );
};

export default TermsConditionsPage;
